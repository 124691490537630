
@font-face {
  font-family: 'Poppins-Black';
  src: url('./assets/fonts/Poppins-Black.ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('./assets/fonts/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'Poppins-Extralight';
  src: url('./assets/fonts/Poppins-ExtraLight.ttf');
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  overflow-x: hidden;
  overscroll-behavior: smooth;
  background-color: black;
}





