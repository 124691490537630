
.nav-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* position: absolute;
    z-index: 50; */
    background-color: transparent;
    width: 100%;
    font-family: 'Poppins-Medium';
    mix-blend-mode: difference;
    padding: 10px;
}

.nav-about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 50;
    width: 100%;
    font-family: 'Poppins-Medium';
    mix-blend-mode: difference;
    padding: 10px;
}

.nav-bar-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 50;
    background-color: transparent;
    width: 100%;
    font-family: 'Poppins-Medium';
    color: white;
    padding: 10px;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 7vmin;
    padding: 0 0px;
    background-color: transparent;
}

/* .logo-white {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 9vmin;
    padding: 0 15px;
    background-color: transparent;
    margin-top: 5px;
    margin-left: -10px;
} */

.nav-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 0px;
    padding: 0 20px;
    background-color: transparent;
}

.work-button, .about-button {
    font-size: 2.5vmin;
    cursor: pointer;
    font-family: 'Poppins-Light';
    transition: all 300ms ease-in-out;
    background-color: transparent;
}

.divider {
    color: white;
    font-size: 2.5vmin;
    font-family: 'Poppins-Light';
    background-color: transparent;
}
   

.work-button:hover {
    /* font-family: 'Poppins-Bold'; */
    font-weight: bolder;
    scale: calc(1.1);
    transition: all .300ms ease-in-out;
}

.about-button:hover {
    /* font-family: 'Poppins-Bold'; */
    font-weight: bolder;
    scale: calc(1.1);
    transition: all .300ms ease-in-out;
}

