
.home-container {
    height: 100dvh;
    width: 100dvw;
}

.enter-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* object-position: 100% center; */
    z-index: 1;
}